












































































import { Component, Vue } from "vue-property-decorator"
import topNav from "../../components/topNav.vue"
import { Field } from 'vant';
import { Popup } from 'vant';
import { Cascader } from 'vant';
import { Switch } from 'vant';

@Component({
	components: {
		topNav,
    [Field.name]:Field,
    [Popup.name]:Popup,
    [Cascader.name]:Cascader,
    [Switch.name]:Switch
	}
})
export default class Default extends Vue {
  receiverAddressTitle: string = ""
  showBalloons: boolean = false
	balloonsMessage: string = ""
  show:boolean=false
  fieldValue:string=""
  cascaderValue:string=""
  options:Array<any> = []
  orderId:any=null
  userInfo:any={
    receiverName:"",
    receiverMobile:null,
    receiverEmail:"",
    town:"",
    detailAddress:"",
    isDefault:false
  }
  fieldNames:any={
    text: 'text',
    value: 'code',
    children: 'children',
  }

  async created() {
    const userInfo: any = this.$route.query.userInfo;
    this.orderId=this.$route.query.orderId;
    if(userInfo!=undefined) {
      this.userInfo = JSON.parse(decodeURIComponent(userInfo))
      this.receiverAddressTitle="编辑地址"
      this.fieldValue=this.userInfo.province?`${this.userInfo.province}/${this.userInfo.city}/${this.userInfo.region}`:'';
    } else if(this.orderId!=undefined){
      this.receiverAddressTitle="修改地址";
      let obj:any=await this.getAddress();
      this.userInfo=obj.data;
      this.fieldValue=this.userInfo.province?`${this.userInfo.province}/${this.userInfo.city}/${this.userInfo.region}`:'';
    } else{
      this.receiverAddressTitle="新增地址"
    }
    this.getCity()
  }

  getAddress(){
    return new Promise((resolve,reject)=>{
      this.$api.getOrderDetail(this.orderId).then((res:any)=>{
        resolve(res)
      })
    })
  }
  getCity(){
    this.$api.receiverRegion().then((res:any)=>{
      this.options=this.getTreeData(res.data)
    })
  }
  getTreeData(data:any){
    for (let i=0; i<data.length; i++) {
      if(data[i].children.length<1) {
        data[i].children=undefined
      } else {
        this.getTreeData(data[i].children)
      }
    }
    return data
  }
  phoneReg() {
		var RegCellPhone = new RegExp(/^1\d{10}$/)
		if (!RegCellPhone.test(this.userInfo.receiverMobile)) {
      this.showToast('请输入正确的邮箱')
		}
	}
  emailReg() {
		var reg = new RegExp(
			/^\w+((-\w+)|(\.\w+))*\@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z0-9]+$/
		)
		if (!reg.test(this.userInfo.receiverEmail)) {
      this.showToast('请输入正确的邮箱')
		}
	}
  // 全部选项选择完毕后，会触发 finish 事件
  onFinish({selectedOptions}:{selectedOptions:any}) {
    this.show = false;
    this.fieldValue = selectedOptions.map((option:any) => option.text).join('/');
    this.userInfo.province=selectedOptions[0].text;
    this.userInfo.city=selectedOptions[1].text;
    this.userInfo.region=selectedOptions[2].text;
  }
  submitForm() {
    let regEmail=new RegExp(
			/^\w+((-\w+)|(\.\w+))*\@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z0-9]+$/
		)
    if(
      this.userInfo.receiverName=="" ||
      this.userInfo.receiverEmail=="" ||
      this.fieldValue=="" ||
      this.userInfo.town=="" ||
      this.userInfo.detailAddress==""
    ){
      this.showToast('填写完整信息')
      return
    }
    if(!regEmail.test(this.userInfo.receiverEmail)) {
      this.showToast('请输入正确的邮箱')
      return
    }
    if(this.receiverAddressTitle=="修改地址") {
      console.log(1);
      this.userInfo.orderId=this.orderId;
      this.$api.fuaorderUpdateAddress(this.userInfo).then((res:any)=>{
        window.location.href = "javascript:history.go(-1)"
      })
    } else {
      let str=JSON.stringify(this.userInfo).replace(/receiverName/g,'name').replace(/receiverMobile/g,'mobile').replace(/receiverEmail/g,'email');
      let obj=JSON.parse(str);
      this.$api.receiverAdd(obj).then((res:any)=>{
        window.location.href = "javascript:history.go(-1)"
      })
    }
  }
  delForm() {
    this.$api.receiverDel({
      id:this.userInfo.receiverId
    }).then((res:any)=>{
      window.location.href = "javascript:history.go(-1)"
    })
  }
  showToast(val:any) {
    this.balloonsMessage = val
    this.showBalloons = true
    let data: any = [this.balloonsMessage, this.showBalloons]
    this.VueFunc.$emit("balloons", data)
    setTimeout(() => {
      this.showBalloons = false
      data = [this.balloonsMessage, this.showBalloons]
      this.VueFunc.$emit("balloons", data)
    }, 2000)
  }
  cancelForm() {
    window.location.href = "javascript:history.go(-1)"
  }

}
